import React, { Component } from "react"
import { Link } from "gatsby"

// Layout
import { Row, Cell, View, Panel } from "~layout/Layout"

// Styles
import Styles from "./motto.module.styl"

// Images
import ImagePill from "~images/motto/pill.png"
import ImageBomb from "~images/motto/bomb.png"
import ImageCash from "~images/motto/cash.png"
import ImageNails from "~images/motto/nails.png"
import ImageFinger from "~images/motto/finger.png"

// Libs
import anime from "animejs/lib/anime.es.js"

// Components
import Metas from "~misc/Metas"

class MottoPage extends Component {
    constructor(props) {
        super(props)

        this.pairs = [
            {
                text:
                    "&laquo;&nbsp;Au doigt mouillé, on va être sur du bon vieux flash&nbsp;&raquo;",
                image: ImageFinger,
            },
            {
                text:
                    "&laquo;&nbsp;On a conçu votre site, comme une expérience immersive et aspirationnelle !&nbsp;&raquo;",
                image: ImagePill,
            },
            {
                text:
                    "&laquo;&nbsp;Votre site se doit d’être disruptif pour être en rupture avec les codes du marché.&nbsp;&raquo;",
                image: ImageBomb,
            },
            {
                text:
                    "&laquo;&nbsp;Il faut penser grand pour générer du traffic !&nbsp;&raquo;",
                image: ImageCash,
            },
            {
                text:
                    "&laquo;&nbsp;Plus qu’une couche de vernis? Ça va être une vraie rupture&nbsp;&raquo;",
                image: ImageNails,
            },
        ]

        this.randomNumber = this.getNumberBetween(0, this.pairs.length - 1)
        this.textRefs = []
        this.imageRefs = []

        // Refs
        this.mottoInner = React.createRef()
        this.text = React.createRef()
        this.stamp = React.createRef()
    }

    state = {
        shouldGeneratePills: false,
        shouldStartAnimation: false,
    }

    componentDidMount() {
        this.setState({
            text: this.pairs[this.randomNumber].text,
            image: this.pairs[this.randomNumber].image,
        })

        console.log(this.state)

        this.setWatchdog()
    }

    setAnimations() {
        const timeline = anime.timeline({
            autoplay: false,
        })

        const tweenDefaults = {
            bottom: ["100%", "0"],
            duration: 200,
            easing: function (el, i, total) {
                return function (t) {
                    const n1 = 7.5625
                    const d1 = 2.75

                    if (t < 1 / d1) {
                        return n1 * t * t
                    } else if (t < 2 / d1) {
                        return n1 * (t -= 1.5 / d1) * t + 0.75
                    } else if (t < 2.5 / d1) {
                        return n1 * (t -= 2.25 / d1) * t + 0.9375
                    } else {
                        return n1 * (t -= 2.625 / d1) * t + 0.984375
                    }
                }
            },
            autoplay: false,
        }

        // timeline.add({
        //     targets: this.textRefs,
        //     delay: anime.stagger(100),
        //     ...tweenDefaults
        // });

        for (let i = 0, j = this.imageRefs.length; i < j; i++) {
            let currentEl = this.imageRefs[i],
                rotateValue = currentEl.getAttribute("data-rotation") * 1

            timeline.add(
                {
                    targets: currentEl,
                    ...tweenDefaults,
                    scale: [3, 3],
                    rotate: [rotateValue, rotateValue],
                },
                i === 0 ? `+=500` : `-=50`
            )
        }

        timeline.add({
            targets: this.stamp.current,
            opacity: 1,
            translateX: ["-50%", "-50%"],
            translateY: ["-50%", "-50%"],
            rotate: [
                this.getNumberBetween(-9, 9),
                this.getNumberBetween(-9, 9),
            ],
            autoplay: false,
            duration: 1,
            delay: 500,
        })

        timeline.play()
    }

    setWatchdog() {
        // Waiting for the refs to be added to the DOM
        const checker = setInterval(() => {
            if (this.mottoInner.current !== null) {
                this.setState({
                    shouldGeneratePills: true,
                })
            }

            if (this.imageRefs.length && this.stamp.current !== null) {
                clearInterval(checker)
                this.setAnimations()
            }
        })
    }

    generateStringFromText() {
        this.textRefs = []

        const Text = this.state.text.map((word, index) => {
            return (
                <span
                    key={`word-motto-${index}`}
                    style={{
                        transform: "translateY(30%)",
                        opacity: "0",
                    }}
                    dangerouslySetInnerHTML={{ __html: word }}
                    ref={(ref) => {
                        if (ref) {
                            this.textRefs.push(ref)
                        }
                    }}
                ></span>
            )
        })

        return Text
    }

    getNumberBetween(min, max, isFloored = true) {
        if (isFloored) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        } else {
            return Math.random() * (max - min + 1)
        }
    }

    generateImages() {
        this.imageRefs = []
        const Images = []

        for (let i = 0, j = 15; i < j; i++) {
            const styles = {
                zIndex: `${this.getNumberBetween(0, 2)}`,
                left: `${this.getNumberBetween(
                    0,
                    this.mottoInner.current.clientWidth
                )}px`,
                bottom: `100%`,
            }

            const Element = (
                <img
                    ref={(ref) => {
                        if (ref) {
                            this.imageRefs.push(ref)
                        }
                    }}
                    src={this.state.image}
                    key={`image-motto-${i}`}
                    className={`${Styles.Motto__image}`}
                    data-rotation={`${this.getNumberBetween(0, 360, false)}`}
                    style={styles}
                />
            )

            Images.push(Element)
        }

        return Images
    }

    generateStamp() {
        const styles = {
            opacity: 0,
        }

        const Stamp = (
            <div
                className={`${Styles.Motto__stamp}`}
                style={styles}
                ref={this.stamp}
            >
                <p className={`${Styles.Motto__teasing}`}>
                    Adoptez le bon site
                </p>
            </div>
        )

        return Stamp
    }

    render() {
        const Stamp = this.generateStamp()
        let Images = null
        let Text = null

        if (this.mottoInner.current != null && this.state.image) {
            Images = this.generateImages()
        }

        // if (this.state.text) {
        //     Text = this.generateStringFromText();
        // }

        return (
            <View name="motto" theme="home">
                <Metas shouldIndex={false} />

                <div className={`${Styles.Motto}`}>
                    <Row full={true}>
                        <Cell start="0" end="12">
                            <div
                                className={`${Styles.Motto__inner}`}
                                ref={this.mottoInner}
                            >
                                <div className={`${Styles.Motto__text}`}>
                                    <h1
                                        className={`${Styles.Motto__heading}`}
                                        ref={this.text}
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.text,
                                        }}
                                    ></h1>
                                </div>
                            </div>
                        </Cell>
                    </Row>

                    {Images}
                    {Stamp}
                </div>
            </View>
        )
    }
}

export default MottoPage
